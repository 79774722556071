/** @format */

.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 2px solid #ddd;
}
.tabs > li {
  padding: 0.5rem 1rem;
  /* margin: 0 1rem; */
  border: 1px solid #ccc;
  background-color: #ddd;
  border-bottom: 0;
  border-top-right-radius: 5px;
  top: 1px;
}

.current {
  background-color: white !important;
  color: #ddd;
  font-weight: bold;
  position: relative;
}
.content {
  border-top: 2px solid #ccc;
  padding: 1rem 2rem;
}
